import request from '@/plugins/axios'

// 第三方订单列表
export const apiFactoryOrderList = (params: any) => request.get('/factory.order/goodslists', { params })

export const apiImportOrderList = (params: any) => request.get('/order.order/imlists', { params })

export const apiShopOrderList = (params: any) => request.get('/order.order/lists', { params })

export const apiThirdOrderList = (params: any) => request.get('/factory.order/lists', { params })

// 推送订单
export const apiOrderDistribution = (params: any) => request.post('/factory.order/distribution', params)

// 设置物流单号
export const apiSetDeliverySn = (params: any) => request.post('/factory.order/setDeliverySn', params)

//
export const apiSetDelivery = (params: any) => request.post('/factory.order/delivery', params)
